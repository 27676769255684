import { AxiosError } from 'axios'
import { FieldPath, FieldValues, UseFormReturn, get as getErrorFromForm } from 'react-hook-form'
import { PathValue } from 'react-hook-form/dist/types/path/eager'
import { hasObjectAttributes } from './ObjectHelper'

export const registerFormInputErrors = <E extends FieldValues>(
    { formState: { errors } }: { formState: UseFormReturn<E>['formState'] },
    field: FieldPath<E>,
    defaultMessage?: string
): { valid: boolean; message?: string } => {
    if (!errors) {
        return { valid: true }
    }
    return {
        valid: !getErrorFromForm(errors, field),
        message: (getErrorFromForm(errors, field)?.message as string | undefined) || defaultMessage
    }
}

export const registerFormSectionErrors = <R extends FieldValues>(
    { formState: { errors } }: { formState: UseFormReturn<R>['formState'] },
    ...attributes: PathValue<R, FieldPath<R>>[]
): { valid: boolean } => {
    return {
        valid: !hasObjectAttributes(errors, attributes)
    }
}

export const setErrorsFromApiToForm = (
    error: AxiosError,
    form: Pick<UseFormReturn, 'setError'> | UseFormReturn['setError']
) => {
    const setError = typeof form === 'function' ? form : form.setError
    const errors = Object.entries(error.response.data?.['errors']).map(([key, value]) => {
        return {
            attribute: key,
            type: 'manual',
            message: value as string
        }
    })

    errors.forEach(item => {
        return setError(item.attribute, {
            type: item.type,
            message: item.message
        })
    })

    return errors
}
