import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type CardActionsProps = HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean
    border?: boolean
    containerClassName?: string
}

const CardActions = ({
    children,
    border = false,
    className,
    disabled = false,
    containerClassName,
    ...props
}: PropsWithChildren<CardActionsProps>) => {
    return (
        <div
            className={buildClassesWithDefault(
                { 'card-actions-border': border, disabled, 'card-actions': true },
                containerClassName
            )}
        >
            <div {...props} className={buildClassesWithDefault('card-actions-container', className)}>
                {children}
            </div>
            {disabled && <div className='disabled-overlay' />}
        </div>
    )
}

CardActions.displayName = 'CardActions'

export default CardActions
